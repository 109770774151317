import {type SDKOptions} from './sdk-core';

/**
 * Internal private API for controlling the Express Checkout iframe
 */
type ExpressCheckoutAPI = {
	show(): void;
	hide(): void;
	maybeMount(): Promise<void>;
	onClose(callback: (event: CloseFlags) => void): void;
};

/**
 * Flags that are sent back from the Express Checkout iframe when it is closed
 */
type CloseFlags = {
	redirect?: string;
	reload?: boolean;
	refresh?: boolean;
};

/**
 *
 */
function expressCheckoutInstance(sdkContext: SDKOptions) {
	const $iframeBackdrop = document.querySelector<HTMLDivElement>('#peachpay-checkout-backdrop');
	if (!$iframeBackdrop) {
		throw new Error('Integration error. The Express Checkout backdrop element is missing.');
	}

	const $loadingSpinner = $iframeBackdrop.querySelector<HTMLSpanElement>('.loading-spinner');
	if (!$loadingSpinner) {
		throw new Error('Integration error. The Express Checkout "loading spinner" element is missing.');
	}

	const $slowLoading = $iframeBackdrop.querySelector<HTMLSpanElement>('.slow-loading');
	if (!$slowLoading) {
		throw new Error('Integration error. The Express Checkout "slow loading" message element is missing.');
	}

	const $errorLoading = $iframeBackdrop.querySelector<HTMLSpanElement>('.error-loading');
	if (!$errorLoading) {
		throw new Error('Integration error. The Express Checkout "error loading" message element is missing.');
	}

	const $closeLoading = $iframeBackdrop.querySelector<HTMLAnchorElement>('.close-loading');
	if (!$closeLoading) {
		throw new Error('Integration error. The Express Checkout "close" button element is missing.');
	}

	let iframeMountStarted = false;
	let $iframe: HTMLIFrameElement | null = null;

	const checkout = {
		/**
		 * Shows the PeachPay Checkout
		 */
		show(): void {
			try {
				lockDocumentBody();

				$iframeBackdrop.style.display = 'flex';

				$iframe?.classList.remove('hide');
				$iframe?.contentWindow?.focus();

				$iframe?.contentWindow?.postMessage({type: 'peachpay_checkout_opened'}, location.origin);
			} catch (e) {
				sdkContext.errorReporter(e);
			}
		},
		/**
		 * Hides the PeachPay Checkout
		 */
		hide(): void {
			try {
				unlockDocumentBody();

				$iframe?.contentWindow?.blur();
				$iframe?.classList.add('out');

				$iframeBackdrop?.classList?.add('out');

				setTimeout(() => {
					$iframeBackdrop.style.display = 'none';
					$iframeBackdrop?.classList?.remove('out');

					$iframe?.classList.add('hide');
					$iframe?.classList.remove('out');
				}, 500);

				$iframe?.contentWindow?.postMessage({type: 'peachpay_checkout_closed'}, location.origin);
			} catch (e) {
				sdkContext.errorReporter(e);
			}
		},
		/**
		 * Mounts the PeachPay Checkout iFrame DOM element if it has not already been mounted. Waits for the iFrame to load before resolving.
		 */
		async maybeMount(): Promise<void> {
			if ($iframe || iframeMountStarted) {
				return;
			}

			iframeMountStarted = true;
			$iframe = await new Promise((resolve, reject) => {
				const slowLoadingTimer = setTimeout(() => {
					$slowLoading.classList.remove('hide');
				}, 8000);

				const $iframe = document.createElement('iframe');
				$iframe.setAttribute('id', 'peachpay-checkout-iframe');
				$iframe.setAttribute('title', 'PeachPay Checkout');
				$iframe.setAttribute('class', 'hide');

				$iframe.onload = () => {
					clearTimeout(slowLoadingTimer);
					$loadingSpinner.classList.add('hide');
					$slowLoading.classList.add('hide');
					$closeLoading.classList.add('hide');
					resolve($iframe);
				};

				$iframe.onerror = e => {
					clearTimeout(slowLoadingTimer);
					$loadingSpinner.classList.add('hide');
					$slowLoading.classList.add('hide');
					$errorLoading.classList.remove('hide');
					$iframe.remove();
					reject(e);
				};

				$iframe.src = sdkContext.checkoutURL;
				$iframeBackdrop.insertAdjacentElement('beforeend', $iframe);
			});

			if (!$iframeBackdrop.classList.contains('hide')) {
				$iframe?.classList.remove('hide');
				$iframe?.contentWindow?.focus();
			}

			window.addEventListener('message', e => {
				if (e.origin !== location.origin) {
					return;
				}

				if (e.data.type === 'peachpay_checkout_close') {
					checkout.hide();
				}
			});
		},
		/**
		 * Registers a callback that is called when the PeachPay Checkout is closed
		 */
		onClose(callback: (event: CloseFlags) => void): void {
			window.addEventListener('message', e => {
				if (e.origin !== location.origin) {
					return;
				}

				if (e.data.type === 'peachpay_checkout_close_flags') {
					const data = e.data.data as CloseFlags;
					callback(data);
				}
			});
		},
	} satisfies ExpressCheckoutAPI;

	$closeLoading.addEventListener('click', e => {
		e.preventDefault();
		checkout.hide();
	});

	return checkout;
}

const handleTouchMove = (e: Event) => {
	e.preventDefault();
};

function lockDocumentBody() {
	// We get the scrollbar width because when the `.peachpay-lock-body` class is applied to the body it
	// adds `overflow:hidden` which causes the scrollbar to be removed. To prevent layout shifts we add
	// a right padding to the body to equal the scrollbar width.
	const bodyScrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
	document.body.style.setProperty('--peachpay-scrollbar-width', `${bodyScrollBarWidth}px`);
	document.body.classList.add('peachpay-lock-body');
	document.body.addEventListener('touchmove', handleTouchMove, false);
}

function unlockDocumentBody() {
	document.body.classList.remove('peachpay-lock-body');
	document.body.removeEventListener('touchmove', handleTouchMove, false);
}

export {
	type CloseFlags,
	expressCheckoutInstance,
};
