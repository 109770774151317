// IOS Safari/Desktop Safari browsers < 10.3
import 'url-search-params-polyfill';
import 'whatwg-fetch';

Promise.allSettled = Promise.allSettled || async function (promises: Array<Promise<unknown>>) {
	const mappedPromises = promises.map(async p => p
		.then(value => ({
			status: 'fulfilled',
			value,
		}))
		.catch((reason: unknown) => ({
			status: 'rejected',
			reason,
		})));
	return Promise.all(mappedPromises);
};
